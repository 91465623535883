<template>
    <div class="projects">
        <main class="mt-0 main-content main-content-bg">
            <section>
               
                <!-- <div class="container" style="background-color:rgba(0,0,0,0);!important; "> -->
                <img src='@/assets/img/payment-2.png' style="position:absolute; ">
                <div class="section text-center" >
                    <br><br><br>
                    <h3 class="text-dark">Payment: {{Status}}</h3>
                    <h3 class="text-dark"></h3>
                    <!-- <h3 v-if="Status=='SUCCESS'" class="text-dark">Transaction Complete</h3> -->
                    <!-- <h3 v-else class="text-dark">Transaction Failed</h3> -->
                    <h5 class="text-dark">{{ orderResult }}</h5>
                    <br><br><br>
                    <div style="position:absolute; left:0; width: 100%; bottom: 0;">
                    <h6 class="text-dark text-center" style="text-align: center;" >For inquiry contact at <a href="mailto:contact@rplab.ai" class="text-gored">contact@rplab.ai</a></h6>
                    </div>
                    <!-- </div> -->
               
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import { API } from 'aws-amplify';
export default {
    name: 'Payment',
    created:  function () 
    {
        (async ()=>
        {
            this.loading = true;
            
            this.loading = false;
        })();
    },
    
    watch: {
		loading: function (loading) {
			if (loading) 
			{
				this.loader = this.$loading.show({container: this.$refs.loadingContainer});
			} else 
			{
				this.loader.hide();
				this.loader = null;
			}
		}
	},
    data() {
        return {    
           loading: false,
           Status: '',
           MerchantID: '',
           Version: '',
           TradeInfo: '',
           MerchantOrderNo: '',
           orderResult:'',
           
        };
    },
    computed: {
      
    },
    async mounted()
    {
        console.log(this.$route.query)
        //this.Status = this.$route.query.Status;
        //this.MerchantID = this.$route.query.MerchantID;
        //this.Version = this.$route.query.Version;
        //this.TradeInfo = this.$route.query.TradeInfo;
        this.MerchantOrderNo = this.$route.query.MerchantOrderNo;
        const confirmDetails = {    
            headers: { 
            "Content-Type":"application/json"
            },
            body: {
            'MerchantOrderNo': this.MerchantOrderNo
            }
        }
        this.loading = true;
        try{
            const Result = await API.post('rpgPayment','/confirm', confirmDetails);
            this.orderResult = Result.message;
            this.Status = Result.Status;
            // Show a success message within this page, e.g.
            //this.$router.push({name:'profile'});
            //await app.profile();
        }catch(err)
        {
            console.log(err.message)
            // const element = document.getElementById('paypal-button-container');
            // element.innerHTML = '';
            // element.innerHTML = '<h3>Something went wrong!</h3>';
        }
        this.loading = false;
    },
    methods: {
        
    },
};
</script>



<style scoped>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  overflow-y: scroll;
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: -1;
  background: #eee !important;

}


.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}

section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007bff;
    margin-bottom: 50px;
    text-transform: uppercase;
}
div.ex1 {
  width: 300px;
  margin: auto;
  text-align: left;
  
}
.card {
   
    background: #ffffff;
    margin-bottom: 30px;
}
.asdasd
{
    display: inline-block
   
} */
img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: auto; /* to keep proportions */
  height: auto; /* to keep proportions */
  max-width: 100%; /* not to stand out from div */
  max-height: 100%; /* not to stand out from div */
  margin: auto auto 0; /* position to bottom and center */
  z-index: -1;
}

</style>