
<template>
   <div >
      <div id="audio">
        <audio id="audio2" autoplay></audio>
        <audio id="replay" autoplay></audio>
      </div>
      <div id="video" class="video-container">
        <video id="video2" class="video_bg" muted playsinline autoplay ></video>
      </div>
      <div class="box" id="engine" ref="engine" v-on:mousedown="e=>{startX = e.x, startY = e.y}" v-on:mouseup="collapse($event)"  v-on:keyup.enter="show=!show"/>
      <editor-panel
        ref="panel"
        :save="saveProject"
        :project_name = 'mutable_project_name'
        :class="[
        this.$store.state.showEditorPanel ? 'show' : '',
      ]"
    />
  </div>
</template>
<script>
  /* eslint-disable */
import { Engine, engine_event } from '@/engine'
import { API, Storage} from 'aws-amplify';
import * as aws_helper from "@/amplify_helper.js";
import EditorPanel from "@/components/EditorPanel.vue";
import { zipSync, strToU8, strFromU8, unzipSync } from 'fflate';
import data2blob from 'vue-image-crop-upload/utils/data2blob.js';
import {v4 as uuid} from 'uuid';
import { mapMutations } from "vuex";

strFromU8;
unzipSync;
//import * as THREE from 'three';
let engine  = new Engine();
engine;
export default {
  name: 'starter',
  data()
  {
    return{
      loading: false,
      motions: [],
      mutable_project_name: '',
      project_id: null,
      panel: null,
      startX:0,
      startY:0,
      
    }
  },
  components:
  {
    EditorPanel,
  },
  mounted : function()
  {
    engine_event.emit('onInitStats');
    
    (async ()=>
    {
      this.loading = true;
       this.lambda = await this.callLambda();
       this.GetMotions(this.lambda);
       this.GetCharacters(this.lambda);
       this.GetEnvironments(this.lambda);

      
      //let basic_zip = await aws_helper.getS3File('public/projects/'+this.project+'/'+this.project+'.zip');
      //let basic_zip = await aws_helper.getS3File(this.project);
      let basic_zip ='';
      let params = {
            'queryStringParameters':
            {
                project_id: this.project
            }
        }
      const data = await API.get('getassets','/project', params);
      console.log(data);
      if(data.projects.length==0)
      {
        basic_zip = await aws_helper.getS3File('public/projects/'+this.project+'/'+this.project+'.zip','no-store');
        this.mutable_project_name = this.project;
      }
      else{
        this.mutable_project_name = data.projects[0][2];
        basic_zip = await aws_helper.getS3File(data.projects[0][0],'no-store');
        this.project_id = this.project;
      }
      let panel = this.$refs.panel;
     
      panel.SetName(this.mutable_project_name);
      console.log(panel);
      let blob = await fetch(basic_zip);
      let zipped = new Uint8Array(await blob.arrayBuffer());
      const unzipped = unzipSync(zipped);
      let json = strFromU8(unzipped['app.json' ])
      const jdata = JSON.parse(json);
      this.$store.state.hasSlides = false;
      this.$store.state.enviroments_3d = false;
      if(jdata.slides && jdata.slides.length>0)
      {
        this.$store.state.hasSlides = true;
      }
      if(jdata.enviroments_3d && jdata.enviroments_3d.length>0)
      {
        this.$store.state.has3dEnvironment = true;
      }
      {
       
        let promiseResolve;
        const promise = new Promise((resolve) => {promiseResolve=resolve;});
        await engine_event.emit('onLoadProject', json, promiseResolve);
        await promise;
      }
      
      engine_event.emit('hasScreen',(value) => {this.$store.state.hasSlides=value;});
      engine_event.emit('loadCamerasFromFile',jdata.cameras);
        

      {
       
        // let promiseResolve;
        // const promise = new Promise((resolve) => {promiseResolve=resolve;});
        // await engine_event.emit('onLoadEnvironment', this.lambda.environments[0].slice(0,6),this.lambda.environments[0][6], promiseResolve);
        // await promise;
      }
      
      let promiseResolve;
      const promise = new Promise((resolve) => {promiseResolve=resolve;});
      await engine_event.emit('onLoadAnimation', "public/motions/rpg_idle_fingers.glb", promiseResolve);//this.motions[0]
      await promise;
      
      {
        const promise = new Promise((resolve) => {promiseResolve=resolve;});
        await engine_event.emit('onLoadCameraMotion', "public/motions/camera_motions.glb", promiseResolve);
        await promise;
      }
      {
        // let promiseResolve;
        // const promise = new Promise((resolve) => {promiseResolve=resolve;});
        // engine_event.emit('onLoadGLB', 'public/RedPillBot.glb', 'public/RedPillBot.json', promiseResolve);
        // promise;
      }
      // {
      //     let promiseResolve;
      //     const promise = new Promise((resolve) => {promiseResolve=resolve;});
      //     engine_event.emit('onLoad3DEnvironment', 'public/Gym.glb',true,promiseResolve);
      //     promise;
      //   }

      //   {
      //     let promiseResolve;
      //     const promise = new Promise((resolve) => {promiseResolve=resolve;});
      //     engine_event.emit('onLoadAnimatedModel', 'public/GymInstructor.glb', promiseResolve);
      //     promise;
      //   }
      // {
      //     let promiseResolve;
      //     const promise = new Promise((resolve) => {promiseResolve=resolve;});
      //     engine_event.emit('onLoad3DEnvironment', 'public/Stage.glb',true, promiseResolve);
      //     promise;
      //   }

      //   {
      //     let promiseResolve;
      //     const promise = new Promise((resolve) => {promiseResolve=resolve;});
      //     engine_event.emit('onLoad3DEnvironment', 'public/stage_people.glb', false, promiseResolve);
      //     promise;
      //   }
      //   {
      //     let slides = [];
      //     slides.push('public/slides/Slide1.JPG');
      //     slides.push('public/slides/Slide2.JPG');
      //     slides.push('public/slides/Slide3.JPG');
      //     slides.push('public/slides/Slide4.JPG');
      //     slides.push('public/slides/Slide5.JPG');
      //     slides.push('public/slides/Slide6.JPG');
      //     slides.push('public/slides/Slide7.JPG');
      //     slides.push('public/slides/Slide8.JPG');
      //     slides.push('public/slides/Slide9.JPG');
      //     slides.push('public/slides/Slide10.JPG');
      //     let promiseResolve;
      //     const promise = new Promise((resolve) => {promiseResolve=resolve;});
      //     engine_event.emit('onLoadSlides', slides, promiseResolve);
      //     promise;
      //   }
       this.loading = false;
       
       
    })();
    this.configureStorage();
  },
  methods:
  {
    ...mapMutations([ "closeAllPanels"]),
    configureStorage()
    {
        Storage.configure({
            customPrefix: {
                protected: `protected/${this.$store.state.email}/`
            }
        })
    },
    async callLambda()
    {
     try{
      //var user = await Auth.currentAuthenticatedUser();
      const data = await API.get('getassets','/items');
      console.log(data);
      return data;
     }catch(err)
     {
        console.log('error: ',err);
     }
    },
    async GetMotions(lambda) 
    {
      //console.log(lambda.motions);
      this.motions.push(lambda.motions)
      //this.missing_thumb = await aws_helper.getS3File(lambda.missing);
      //this.glass_thumb = await aws_helper.getS3File(lambda.glass);
    },
    async GetEnvironments(lambda) 
		{
      this.$store.state.environments = [];
      //if(this.$store.state.environments.length>0) return;
			for(var c in lambda.environments)
			{
        //console.log(lambda.environments[c]);
				this.$store.state.environments.push(lambda.environments[c]);
        let st = lambda.environments[c][0].indexOf("cubemaps/")+9;
        let end = lambda.environments[c][0].indexOf("/px.jpg");
        let name = lambda.environments[c][0].substring(st, end);
        lambda.environments[c].push(name);
        lambda.environments[c].push(await aws_helper.getS3File(lambda.environments[c][6]));
        //console.log(this.environments[this.environments.length-1]);
        
			}
      this.$store.state.environments.push(["","","","","","","","Transparent",await aws_helper.getS3File(lambda.glass)]);
		},
    async GetCharacters(lambda) 
		{

      //if(this.$store.state.characters.length>0) return;
      this.$store.state.characters = []
      this.$store.state.characters.push(['',await aws_helper.getS3File('public/thumbs/Custom.jpg'),"","Custom"]);
      this.$store.state.characters.push(['public/RedPillBot.glb',await aws_helper.getS3File('public/RedPillBot.jpg'),"public/RedPillBot.json","RedPillBot"]);
      //engine_event.emit('onLoadGLB', await aws_helper.getS3File('public/RedPillBot.glb'), await aws_helper.getS3File("public/RedPillBot.json"), promiseResolve);
      for(let c in lambda.private_assets)
      {
        this.$store.state.characters.push([lambda.private_assets[c][0],await aws_helper.getS3File(lambda.private_assets[c][1]),'public/GenericRPM.json',lambda.private_assets[c][2]]);
      }
			for(let c in lambda.characters)
			{
        let name = lambda.characters[c][3];
        let attribution = "";
        try{
         
          const local_json_url = await aws_helper.getS3File(lambda.characters[c][2]);
          const jd = await fetch(local_json_url)
          const jdata = await jd.json();
          name = jdata.Name;
          console.log(jdata);
          if(jdata.Attribution)
            attribution = jdata.Attribution;
        }catch(err)
        {
          //console.log("No json data for ",name,err.message);
          //lambda.characters[c].push(lambda.characters[c][3]);
        }
        
        
        let char_url = await aws_helper.getS3File(lambda.characters[c][1]);
        this.$store.state.characters.push([lambda.characters[c][0],char_url,lambda.characters[c][2],name,attribution]);

			}
     
		},
    async saveProject(title)
    {
      this.loading = true;
      console.log("Save Project");
      const toZip = {};
      let promiseResolve;
      const promise = new Promise((resolve) => {promiseResolve=resolve;});
      await engine_event.emit('onSaveProject', promiseResolve);
      let output = await promise;

      let promiseResolve2;
      const promise2 = new Promise((resolve) => {promiseResolve2=resolve;});
      await engine_event.emit('screenshot', promiseResolve2);
      let thumbnail = await promise2;

      let promiseResolve3;
      const promise3 = new Promise((resolve) => {promiseResolve3=resolve;});
      await engine_event.emit('getSlides', promiseResolve3);
      let slides = await promise3;

     

      output.metadata.type = 'App';
      output.metadata.name = this.$refs.panel.mutable_project_name;
      if(this.project_id==null)
      {
        this.project_id = uuid();
      }
      output.metadata.project_id = this.project_id;
      let credentials = await aws_helper.getCredentials();
      let identityId = credentials.identityId;
      for(let i=0; i< slides.length; i++)
      {
        output.slides[i] = "protected/"+this.$store.state.email+"/"+identityId+"/"+this.project_id+"/Slides/Slide"+i+".jpg";
      }
      if(slides.length>0)
        output.metadata.hasSlides = true;

      let waves = [];
      for(let i=0; i<output.recordings.length; i++)
      {
        if(output.recordings[i].audio!=undefined)
        {
          waves.push({name:output.recordings[i].clip.name, blob:output.recordings[i].audio});
          output.recordings[i].audio = "protected/"+this.$store.state.email+"/"+identityId+"/"+this.project_id+"/waves/"+output.recordings[i].clip.name+".wav";
        }
      }
      for(let i=0; i<this.$store.state.cameras.length; i++)
      {
        output.cameras.push(this.$store.state.cameras[i].serialize());
      }

      let meta_file = output.metadata;
      output = JSON.stringify( output, null, '\t' );
      //eslint-disable-line
      output = output.replace( /[\n\t]+([\d\.e\-\[\]]+)/g, '$1' );

      toZip[ 'app.json' ] = strToU8( output );
      //const manager = new THREE.LoadingManager( function () {
       
      const zipped = zipSync( toZip, { level: 9 } );
      const blob = new Blob( [ zipped.buffer ], { type: 'application/zip' } );

      this.save( blob,thumbnail, this.project_id, meta_file, slides, waves);

        //} );
      this.loading = false;  
    },
    async save( blob, thumbnail, project_id, meta_file, slides ,waves) {
      let project_uuid = project_id
      
      const link = document.createElement( 'a' );
      if ( link.href ) {

        URL.revokeObjectURL( link.href );

      }
      await Storage.put(project_uuid+'/project.zip', blob, {
            level: "protected",
            contentType: "application/zip",
        });
        let photoBlob;
        photoBlob = data2blob(thumbnail,"image/jpeg");
        await Storage.put(project_uuid+"/thumbnail.jpg", photoBlob, {
            level: "protected",
            contentType: "image/jpeg",
        });
        await Storage.put(project_uuid+"/meta.json", meta_file, {
            level: "protected",
            contentType: "application/json",
        });

        for(let i=0; i< slides.length; i++)
        {
          let img = slides[i].image.currentSrc;
          const blob=await fetch(img).then(res=>res.blob());
          await Storage.put(project_uuid+"/Slides/Slide"+i+".jpg", blob, {
            level: "protected",
            contentType: "image/jpeg",
          });
        }
        for(let i=0; i< waves.length; i++)
        {
         
          const blob=waves[i].blob;
          await Storage.put(project_uuid+"/waves/"+waves[i].name+".wav", blob, {
            level: "protected",
            contentType: "audio/wav",
          });
        }
      //link.href = URL.createObjectURL( blob );
      //link.download = filename || 'data.json';
      //link.dispatchEvent( new MouseEvent( 'click' ) );

    },
    collapse(event)
    {
      if(event && event.button==0)
      {
        const diffX = Math.abs(event.x - this.startX);
        const diffY = Math.abs(event.y - this.startY);
        let delta = 6;
        if (diffX < delta && diffY < delta) {
          this.closeAllPanels();
        }
        // this.$refs.sidebarright.hide();
        // this.$refs.sidebarleft.hide();
        // if(this.$refs.cam_panel.show == true)
        //   this.$root.$emit('bv::toggle::collapse', 'cam_panel')
        // this.$root.$emit("collapse");
      }
    },

  },
  props: {
    project:
    {
      type: String,
      default:"Test",
    },
    project_name:
    {
      type: String,
      default:"Test",
    }
  },
  watch: {
		loading: function (loading) {
			if (loading) 
			{
				this.loader = this.$loading.show({container: this.$refs.loadingContainer});
			} else 
			{
				this.loader.hide();
				this.loader = null;
			}
		}
	},
};
</script>
<style>
.starter-page {
  min-height: calc(100vh ); 
  height: 100%;
  width: 100%;
  background-color:cadetblue;
}
.box {
  position:fixed;

  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  width: 100%;
  height: 100%;
  z-index: -1;  
}
.video_bg
{
  position:fixed;
  width: 100%;
  top: 0%;
  left: 0%;
  text-align: center;
  z-index:-3;  
}
.video-container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
  z-index:-3;  
}
.video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>
