<template>
  <div class="fixed-plugin_bottom">
    <div class="card blur">
      <div class="pt-1 pb-0 bg-transparent ">
        <div class="mt-0 float-end shadow-lg blur" @click="toggleEditorPanel" v-bind:style="this.$store.state.showEditorPanel? 'width:30px!important; border-radius: 20px; margin-left:10px; margin-top:0px!important; background-color:white':'width:30px!important; border-radius: 20px; margin-left:10px; margin-top:-30px!important; background-color:white'">
          <a class= "project" style="margin-left:8px;">
            <font-awesome-icon v-if="this.$store.state.showEditorPanel" icon="fa-solid fa-angle-down"/>
            <font-awesome-icon v-if="!this.$store.state.showEditorPanel" icon="fa-solid fa-angle-up"/>
          </a>
        </div>
        <div class="float-start">
          <h5 class="mt-0 mb-0" >Project: 
            <input id="name_input" class="name_input" v-model="mutable_project_name" :disabled="disabled == 1" @focusout="onDisableEditName" @change="CalcWidth" :style="{width: reactiveWidth }"/>
            <a class= "project" style="margin-left: 10px;" @click="onEditName">
              <font-awesome-icon icon="fa-solid fa-pen"/>
            </a> 
            <a class= "project" style="margin-left: 10px;" @click="save(mutable_project_name)">
              <font-awesome-icon icon="fa-solid fa-floppy-disk"/>
            </a> 
            <!-- <a class= "project" style="margin-left: 10px;" @click="onExport">
              <font-awesome-icon icon="fa-solid fa-floppy-disk"/>
            </a>  -->
          <!-- </button> -->
          </h5>
          <div class="row">
            
            <div class="col" style="width:30px; margin-top: 10px;">
              <div class="col">
              <a class= "project" @click="onMode('rotate')"><font-awesome-icon icon="fa-solid fa-rotate" /></a>
            </div>
            <div class="col">
              <a class= "project" @click="onMode('translate')"><font-awesome-icon icon="fa-solid fa-arrows-up-down-left-right" /></a>
            </div>
            <div class="col">
              <a class= "project" >fps: {{fps}}</a>
            </div>
            </div>

          </div>
          
        </div>
        <!-- <div style=" display: flex; justify-content: center;">
          <h5 class="mt-0 mb-0" >Camera Mode: </h5>
            <div :key="index" style="margin-left: 10px;"  v-for="(motion, index) in this.$store.state.camera_motions">
                <a
                  class="btn bg-gradient-dark w-100"
                  v-on:click="setCameraMotion(`${index}`)"
                  >{{motion.name}}</a>
            </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import {  engine_event } from '@/engine'
export default {
  name: "editor_panel",
  props: ["save",'project_name'],
  data() {
    return {
      disabled: 1,
      reactiveWidth : 100+ 'px',
      mutable_project_name: '',
      fps: 60,
    };
  },
  mounted ()
  {
    engine_event.addListener('fps', function(value)
    {
      this.fps = value;
    }.bind(this));
  },
  methods: {
    ...mapMutations(["toggleEditorPanel"]),
    onMode(mode)
    {
      engine_event.emit('onMode',mode);
    },
    onEditName()
    {
      this.disabled=0; 
      document.getElementById('name_input').disabled=0;
      document.getElementById('name_input').focus();
      console.log("enable");
    },
    onExport()
    {
      engine_event.emit("onExport");
      console.log("export");
    },
    onDisableEditName()
    {
      this.disabled=1;
      console.log("disable");
    },
    CalcWidth()
    {
      console.log("edit")
      this.reactiveWidth = this.mutable_project_name.length + 'ch';
    },
    SetName(name)
    {
      this.mutable_project_name = name;
      document.getElementById('name_input').value = this.mutable_project_name;
      this.reactiveWidth = this.mutable_project_name.length + 'ch';
    },
    setCameraMotion(index)
    {
      engine_event.emit('onCameraMotion',index);
    }
  },
  computed: {
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },

  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
    this.CalcWidth();
  },
};
</script>
<style scoped>
.name_input
{
  background-color: transparent!important;
  border: 0ch;
  font-family: 'Open Sans';
  font-weight: 600;
  color:  #344767;
  /* width: 50%; */

}
</style>

